import React from 'react';
import dynamic from 'next/dynamic';
import { useTheme } from '@chakra-ui/react';

const TextEditor = dynamic(() => import('react-quill'), { ssr: false });

interface RichTextEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  ['link', 'image', 'video', 'formula'],

  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ 'font': [] }],
  [{ align: [] }],

  ['clean'], // remove formatting button
];

const RichTextEditor = ({ value, onChange }: RichTextEditorProps) => {
  const theme = useTheme();

  return (
    <TextEditor
      theme="snow"
      value={value}
      onChange={onChange}
      modules={{
        toolbar: toolbarOptions,
      }}
      style={{
        borderRadius: theme.border_radius.border_radius_1,
        fontFamily: theme.fonts.font_2,
        border: '1px solid #e2e8f0',
      }}
    />
  );
};

export default RichTextEditor;
