import React, { createContext, useContext, useMemo } from 'react';

interface GlobalSearchContextProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

const GlobalSearchContext = createContext<GlobalSearchContextProps>({
  searchQuery: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSearchQuery: () => {},
});

export const GlobalSearchProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchQuery, setSearchQuery] = React.useState('');

  const value = useMemo(() => ({ searchQuery, setSearchQuery }), [searchQuery, setSearchQuery]);

  return <GlobalSearchContext.Provider value={value}>{children}</GlobalSearchContext.Provider>;
};

export const useGlobalSearch = () => useContext(GlobalSearchContext);
