import React from 'react';
import { Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MarketplacePage } from 'src/api/v1-api';
import { MdEdit, MdMoreVert } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import MobileRowLabel from 'src/components/molecules/mobile-row-label';

interface MarketplacePagesTableProps {
  pages: MarketplacePage[];
  editPage: (page: MarketplacePage) => void;
  onDeletePage: (page: MarketplacePage) => void;
}

const MarketplacePagesTable = ({ pages, editPage, onDeletePage }: MarketplacePagesTableProps) => {
  return (
    <Flex direction="column" width="100%">
      <Flex
        py={4}
        w="100%"
        borderBottom="1px solid"
        borderColor="gray.200"
        gap={4}
        display={{ base: 'none', lg: 'flex' }}
        color="gray.600"
        textTransform="uppercase"
        fontSize="xs"
        fontWeight="bold"
      >
        <Flex w="45%" pl={4}>
          Title
        </Flex>
        <Flex w="45%" pl={4}>
          Slug
        </Flex>
        <Flex w="10%" justifyContent="end" pr={4}>
          Actions
        </Flex>
      </Flex>
      {pages.map((page) => (
        <Flex
          key={page.id}
          flexDirection={{ base: 'column', lg: 'row' }}
          w="100%"
          borderBottom="1px solid"
          borderColor="gray.200"
          py={{ base: 6, lg: 2 }}
          gap={4}
          alignItems="flex-start"
        >
          <Flex w={{ lg: '45%' }} pl={{ base: 0, lg: 4 }} direction="column">
            <MobileRowLabel label="Title" />
            {page.title}
          </Flex>
          <Flex w={{ lg: '45%' }} pl={{ base: 0, lg: 4 }} direction="column">
            <MobileRowLabel label="Slug" />
            {page.slug}
          </Flex>
          <Flex display={{ base: 'flex', lg: 'none' }} direction="row" gap={2} flexWrap={'wrap'}>
            <Button
              size="sm"
              colorScheme="gray"
              onClick={() => editPage(page)}
              leftIcon={<MdEdit />}
            >
              Edit
            </Button>
            <Button
              size="sm"
              colorScheme="gray"
              onClick={() => onDeletePage(page)}
              leftIcon={<FaTrash />}
            >
              Remove
            </Button>
          </Flex>
          <Flex w="10%" justifyContent="end" pr={4} display={{ base: 'none', lg: 'flex' }}>
            <Menu>
              <MenuButton as={IconButton} aria-label="Actions" icon={<MdMoreVert />} />
              <MenuList>
                <MenuItem icon={<MdEdit />} onClick={() => editPage(page)}>
                  Edit
                </MenuItem>
                <MenuItem icon={<FaTrash />} onClick={() => onDeletePage(page)}>
                  Remove
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default MarketplacePagesTable;
