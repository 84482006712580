import { Box, BoxProps } from '@chakra-ui/react';

const IPhoneFrame = ({ children, ...props }: BoxProps) => (
  <Box
    position="relative"
    width="280px"
    height="580px"
    bg="black"
    borderRadius="40px"
    padding="8px"
    boxShadow="0 0 20px rgba(0,0,0,0.2)"
    {...props}
  >
    {/* Notch */}
    <Box
      position="absolute"
      top="0"
      left="50%"
      transform="translateX(-50%)"
      width="150px"
      height="25px"
      bg="black"
      borderBottomRadius="20px"
      zIndex="2"
    />
    {/* Screen Container */}
    <Box
      bg="white"
      height="100%"
      width="100%"
      borderRadius="32px"
      overflow="hidden"
      position="relative"
    >
      {/* Content Wrapper */}
      <Box position="absolute" top="0" left="0" right="0" bottom="0">
        {children}
      </Box>
    </Box>
  </Box>
);

export default IPhoneFrame;
