import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Button,
  Container,
  Stack,
  Text,
  FormControl,
  Input,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { CONTAINER_MAX_WIDTH_1 } from 'src/constants/ui';
import { useState } from 'react';
import useRequest from 'src/utils/hooks/useRequest';
import { MarketplaceInfo } from 'src/api/v1-api';

interface postEmailList {
  email: string;
}

interface Props {
  page: string;
  collect_email_module?: {
    title?: string;
    description?: string;
    cta_text: string;
    pages: string[];
  };
  marketplace?: MarketplaceInfo;
}

export const CollectEmailsModule = ({
  page,
  collect_email_module,
  marketplace: propMarketplace,
}: Props) => {
  const toast = useToast();
  const { marketplace: contextMarketplace } = useMarketplaceContext();
  const marketplace = propMarketplace || contextMarketplace;
  const collectEmailModule = marketplace?.functional?.collect_email_module || collect_email_module;
  const { unauthPost: post } = useRequest();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  if (
    !collectEmailModule ||
    !collectEmailModule.title ||
    !collectEmailModule.pages.includes(page)
  ) {
    return null;
  }

  const isEmailValid = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSignup = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!isEmailValid(email)) {
      toast({
        title: 'Invalid Email',
        description: 'Please enter a valid email address.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      await post<postEmailList>({
        url: `/mailing_list/${marketplace?.id}/signup/`,
        body: { email },
      });
      setLoading(false);
      setSubmitted(true);
    } catch (err) {
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Box as="section" w="full">
      <Box borderTopWidth="1px" w="full" mb={{ base: 0 }}>
        <Container maxW={CONTAINER_MAX_WIDTH_1} py="10px" px={0}>
          <Flex
            justify={{ base: 'start', md: 'space-between' }}
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: 0, md: 5 }}
          >
            <Flex flex={1} pe={{ base: '4', md: '0' }} pb={2}>
              <Box pe={{ base: '4', md: '0' }}>
                <Text fontWeight="medium">{collectEmailModule?.title}</Text>
                <Text color="fg.muted">{collectEmailModule?.description}</Text>
              </Box>
            </Flex>
            {submitted ? (
              <Flex flex={0.5} align="flex-end" justify="flex-end">
                <Alert status="success">
                  <AlertIcon />
                  <AlertDescription>Your email has been added!</AlertDescription>
                </Alert>
              </Flex>
            ) : (
              <Flex flex={1} align="center" justify="center">
                <FormControl as="form" onSubmit={handleSignup}>
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing="3"
                    align={{ base: 'stretch', md: 'center' }}
                  >
                    <Input
                      id="collect-email"
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      background="white"
                      color="black"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                      type="submit"
                      colorScheme="secondary"
                      isLoading={loading}
                      isDisabled={loading}
                    >
                      {collectEmailModule?.cta_text || 'Signup'}
                    </Button>
                  </Stack>
                </FormControl>
              </Flex>
            )}
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default CollectEmailsModule;
