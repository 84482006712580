import {
  Text,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListIcon,
  VStack,
  Flex,
  GridItem,
  useTheme,
} from '@chakra-ui/react';
import { BasicCategory, Currency } from 'src/api/v1-api';
import { FaCheckCircle } from 'react-icons/fa';
import { isEmpty } from 'lodash';
import { formatPrice } from 'src/utils/common';

type PriceCardProps = {
  childCategory: BasicCategory | null;
  selectedCategory: BasicCategory | null;
  parentCategory: BasicCategory | null;
  handleSelectedChild: React.Dispatch<React.SetStateAction<BasicCategory | null>>;
  handleChangeCategory: React.Dispatch<React.SetStateAction<BasicCategory | null>>;
};

const PriceCard = ({
  childCategory,
  selectedCategory,
  parentCategory,
  handleSelectedChild,
  handleChangeCategory,
}: PriceCardProps): JSX.Element => {
  const theme = useTheme();
  const handleClick = () => {
    handleChangeCategory && handleChangeCategory(childCategory);
    handleSelectedChild && handleSelectedChild(childCategory);
  };

  ///@ts-ignore
  const pricing_card = childCategory?.functional?.pay_to_list?.pricing_card;
  const isSelected = selectedCategory?.id === childCategory?.id;
  const isMultiCurrency = (parentCategory?.functional?.currencies_allowed?.length ?? 0) > 1;
  const DEFAULT_CURRENCY = Currency.USD;
  const monthlySubscription = Boolean(childCategory?.functional?.monthly_subscription);
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={'gray.200'}
      borderRadius={theme.border_radius.border_radius_2}
      width={'full'}
    >
      <Box py={4} px={10}>
        <Flex direction="column" alignItems="center">
          {/* Name */}
          <Text fontWeight="500" fontSize="2xl" textAlign="center">
            {!isEmpty(childCategory?.create_listing_name)
              ? childCategory?.create_listing_name
              : childCategory?.name ?? ''}
          </Text>
        </Flex>
        {/* Price */}
        <Grid templateColumns="repeat(5, 1fr)">
          <GridItem
            display={'flex'}
            colSpan={2}
            fontSize="lg"
            fontWeight="600"
            alignItems="center"
            justifyContent="right"
            mr={{ md: 1 }}
          />
          <Flex justifyContent={'center'} alignItems="center" gap={2}>
            <Text>$</Text>
            <Text fontSize="3xl" fontWeight="700">
              {formatPrice(pricing_card?.price, { alwaysCents: false, asDecimal: true })}
            </Text>
            {isMultiCurrency && (
              <Text fontSize="xl" fontWeight="semibold">
                {' '}
                {DEFAULT_CURRENCY}
              </Text>
            )}
            {monthlySubscription && (
              <Text fontSize="xl" fontWeight="semibold">
                /&nbsp;mo.
              </Text>
            )}
          </Flex>
        </Grid>
        {/* Description */}
        <Text fontWeight="300" fontSize="sm" textAlign="center">
          {childCategory?.description ?? ''}
        </Text>
      </Box>
      {/* Features */}
      <VStack
        bg={`${theme.colors.mainText}11`}
        py={4}
        borderBottomRadius={theme.border_radius.border_radius_2}
        alignItems={'start'}
        px={5}
      >
        <List spacing={3} textAlign="start">
          {pricing_card?.features?.map((elem: string, index: number) => (
            <ListItem key={index} fontSize="sm">
              <ListIcon as={FaCheckCircle} color="green.500" /> {elem}
            </ListItem>
          ))}
        </List>
        {/* Button */}
        <Box w="full" pt={7} alignSelf={'center'}>
          <Button
            w="full"
            colorScheme="primary"
            variant={isSelected ? 'solid' : 'outline'}
            onClick={handleClick}
          >
            {childCategory?.functional?.pay_to_list?.pricing_card?.cta ?? pricing_card?.cta ?? ''}
          </Button>
        </Box>
        <Text fontSize={'xs'} fontStyle="italic">
          {pricing_card?.fine_print ?? ''}
        </Text>
      </VStack>
    </Box>
  );
};

export default PriceCard;
