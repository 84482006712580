'use client';
import React, { useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { Tooltip, Button, Center, Text } from '@chakra-ui/react';
import { signIn } from 'next-auth/react';

interface props {
  isRegister: boolean;
  onClose?: () => void;
}

const GoogleSigninButton = ({ isRegister, onClose }: props) => {
  const [inAppBrowser, setInAppBrowser] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const isInAppBrowser = () => {
      if (typeof window === 'undefined' || !('userAgent' in window.navigator)) return false;
      const ua = window.navigator.userAgent;
      if (!ua) return false;
      return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1;
    };

    if (isInAppBrowser()) {
      setInAppBrowser(true);
    }
  }, []);

  const handleSignIn = async (event: any) => {
    event.preventDefault();

    if (inAppBrowser) {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 5000);
    } else {
      onClose?.();
      setTimeout(() => {
        signIn('google');
      }, 100);
    }
  };

  return (
    <>
      <Center px={8} pb={8}>
        <Tooltip
          label={`To sign ${
            isRegister === true ? 'up' : 'in'
          } with Google, please open this link in your dedicated mobile browser.`}
          isOpen={showTooltip}
        >
          <Button
            onClick={handleSignIn}
            w={'full'}
            maxW={'md'}
            variant={'outline'}
            leftIcon={<FcGoogle />}
            data-test="google-signup-btn"
            bgColor="#f9f9f9"
          >
            <Center>
              <Text>{`Sign ${isRegister === true ? 'up' : 'in'} with Google`}</Text>
            </Center>
          </Button>
        </Tooltip>
      </Center>
    </>
  );
};

export default GoogleSigninButton;
