import {
  Container,
  Stack,
  Menu,
  MenuItem,
  Icon,
  Select,
  Heading,
  useTheme,
} from '@chakra-ui/react';
import { BLACK, CONTAINER_MAX_WIDTH_1 } from 'src/constants/ui';
import { FiUser, FiUsers } from 'react-icons/fi';
import { RxCounterClockwiseClock } from 'react-icons/rx';
import { IoBagRemoveOutline } from 'react-icons/io5';
import { BsTag } from 'react-icons/bs';
import { BiLogOut } from 'react-icons/bi';
import { useAuth } from 'src/utils/auth';
import NextLink from 'next/link';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { LoginPrompt } from 'src/components/atoms';
import { useContext } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { MdMailOutline } from 'react-icons/md';

interface MyAccountLayoutProps {
  children: React.ReactNode;
  title: string;
  currentPath: string;
}

const MyAccountLayout = ({ children, title, currentPath }: MyAccountLayoutProps): JSX.Element => {
  const { logout, isAuthenticated, authLoading, hasAdmin } = useAuth();
  const { asPath } = useRouter();
  const theme = useTheme();
  const { marketplace } = useContext(MarketplaceContext);
  const isAdmin = hasAdmin(marketplace?.id);
  const buyerDigestEmailsFlagEnabled = useFeatureFlagEnabled('buyer_digest_emails');

  const handleLogout = async () => {
    const loggedOut = await logout();
    if (loggedOut) {
      window.location.href = '/';
    }
  };

  const handleNavigation = (path: string) => {
    if (path === 'logout') {
      handleLogout();
    } else {
      window.location.href = path;
    }
  };

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={title} />
        <link rel="icon" href={marketplace?.favicon_image || '/marketplace.ico'} />
      </Head>
      <Container maxW={CONTAINER_MAX_WIDTH_1} my={{ base: 4, md: 6, lg: 8 }}>
        <Stack justify="flex-start" align="flex-start" spacing="20px">
          <Heading lineHeight="1.33" fontWeight="semibold" fontSize="24px">
            My Account
          </Heading>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justify="flex-start"
            align="flex-start"
            spacing="20px"
            width="100%"
          >
            <Stack
              justify="flex-start"
              align="flex-start"
              spacing="4px"
              width="259px"
              maxWidth="100%"
            >
              {/* This is the Dropdown for mobile */}
              <Select
                display={{ base: 'block', md: 'none' }}
                onChange={(e) => handleNavigation(e.target.value)}
                value={currentPath}
              >
                <option value="/account/info">Account Info</option>
                <option value="/account/orders">My Orders</option>
                <option value="/account/listings">My Listings</option>
                <option value="/account/sales">My Sales</option>
                <option value="/account/roles">Marketplace Roles</option>
                {isAdmin && <option value="/admin/info">Admin Settings</option>}
                {buyerDigestEmailsFlagEnabled && (
                  <option value="/account/subscriptions">Subscriptions</option>
                )}
              </Select>
              {/* This is the regular menu, hidden on mobile */}
              <Stack
                display={{ base: 'none', md: 'block' }}
                justify="flex-start"
                align="flex-start"
                spacing="4px"
                width="259px"
                maxWidth="100%"
              >
                <Menu>
                  <NextLink href="/account/info">
                    <MenuItem
                      icon={<Icon as={FiUser} boxSize={5} />}
                      bg={asPath === '/account/info' ? 'gray.100' : ''}
                      color={asPath === '/account/info' ? BLACK : ''}
                      borderRadius="md"
                    >
                      Account Info
                    </MenuItem>
                  </NextLink>
                  <NextLink href="/account/orders">
                    <MenuItem
                      icon={<Icon as={RxCounterClockwiseClock} boxSize={5} />}
                      bg={asPath === '/account/orders' ? 'gray.100' : ''}
                      color={asPath === '/account/orders' ? BLACK : ''}
                      borderRadius="md"
                    >
                      My Orders
                    </MenuItem>
                  </NextLink>
                  <NextLink href="/account/listings">
                    <MenuItem
                      icon={<Icon as={IoBagRemoveOutline} boxSize={5} />}
                      bg={asPath === '/account/listings' ? 'gray.100' : ''}
                      color={asPath === '/account/listings' ? BLACK : ''}
                      borderRadius="md"
                    >
                      My Listings
                    </MenuItem>
                  </NextLink>
                  <NextLink href="/account/sales">
                    <MenuItem
                      icon={<Icon as={BsTag} boxSize={5} />}
                      bg={asPath === '/account/sales' ? 'gray.100' : ''}
                      color={asPath === '/account/sales' ? BLACK : ''}
                      borderRadius="md"
                    >
                      My Sales
                    </MenuItem>
                  </NextLink>
                  <NextLink href="/account/roles">
                    <MenuItem
                      icon={<Icon as={FiUsers} boxSize={5} />}
                      bg={asPath === '/account/roles' ? 'gray.100' : ''}
                      color={asPath === '/account/roles' ? BLACK : ''}
                      borderRadius="md"
                    >
                      Marketplace Roles
                    </MenuItem>
                  </NextLink>
                  {buyerDigestEmailsFlagEnabled && (
                    <NextLink href="/account/subscriptions">
                      <MenuItem
                        icon={<Icon as={MdMailOutline} boxSize={5} />}
                        bg={asPath === '/account/subscriptions' ? 'gray.100' : ''}
                        color={asPath === '/account/subscriptions' ? BLACK : ''}
                        borderRadius="md"
                      >
                        Subscriptions
                      </MenuItem>
                    </NextLink>
                  )}
                  <MenuItem
                    icon={<Icon as={BiLogOut} boxSize={5} />}
                    onClick={handleLogout}
                    color=""
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>
            <Stack
              padding="32px"
              borderRadius={theme.border_radius.border_radius_2}
              justify="flex-start"
              align="flex-start"
              spacing="28px"
              borderColor="gray.300"
              borderStartWidth="1px"
              borderEndWidth="1px"
              borderTopWidth="1px"
              borderBottomWidth="1px"
              width="100%"
            >
              {!authLoading && isAuthenticated && <>{children}</>}
              {!authLoading && !isAuthenticated && <LoginPrompt message="to edit settings." />}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default MyAccountLayout;
