import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function useScrollRestoration(): void {
  const router = useRouter();

  useEffect(() => {
    // Only run on the client
    if (typeof window === 'undefined') return;

    // Disable native scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    /**
     * Save the current scroll position (for the route we're leaving).
     */
    function saveScrollPosition(routeKey: string): void {
      sessionStorage.setItem(`scrollPos:${routeKey}`, String(window.scrollY));
    }

    /**
     * Restore the scroll position for the route we're entering.
     */
    function restoreScrollPosition(routeKey: string): void {
      const savedY = sessionStorage.getItem(`scrollPos:${routeKey}`);
      if (savedY) {
        window.scrollTo(0, parseFloat(savedY));
      }
    }

    /**
     * Called when a route change starts; store the position of the route we are leaving.
     */
    function handleRouteChangeStart(): void {
      saveScrollPosition(router.asPath);
    }

    /**
     * Called when a route change is complete; restore the position for the route we just navigated to.
     */
    function handleRouteChangeComplete(url: string): void {
      restoreScrollPosition(url);
    }

    // Listen to Next.js router events
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // Attempt to restore the scroll position on initial render
    restoreScrollPosition(router.asPath);

    // Cleanup event listeners on unmount
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);
}
