import { useState, useEffect, useRef, useCallback, memo } from 'react';
import { Category, Listing, BasicCategory, RootCategory } from 'src/api/v1-api';
import { Flex, Text, useTheme } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ListingCard } from 'src/components/molecules';

interface ListingCarouselProps {
  listings: Listing[];
  columns: number;
  loading?: boolean;
  isFeatured?: boolean;
  titleText?: string;
  actionButtons?: string;
  category?: (Category | BasicCategory) | (Category | RootCategory | null);
}

const SCROLL_DISTANCE = 280;

const ListingCards = memo(function Listings({
  listings,
  category,
  loading = false,
  actionButtons,
  columns,
}: ListingCarouselProps) {
  return (
    <>
      {listings.map((listing) => (
        <ListingCard
          key={listing.id}
          listing={listing}
          actionButtons={actionButtons}
          category={category}
          loading={loading}
          flexProps={{
            flexShrink: 0,
            scrollSnapAlign: 'start',
            basis: {
              base: 'calc(50% - 8px)',
              md: 'calc(50% - 12px)',
              lg: `calc((100% - (${24 * (columns - 1)}px)) / ${columns})`,
            },
          }}
        />
      ))}
    </>
  );
});

const ListingCarousel = ({
  listings,
  columns,
  loading = false,
  isFeatured = false,
  titleText = '',
  actionButtons = '',
  category,
}: ListingCarouselProps) => {
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const handleScroll = useCallback(() => {
    if (divRef.current) {
      const { scrollLeft, offsetWidth, scrollWidth } = divRef.current;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + offsetWidth < scrollWidth);
    }
  }, [setShowLeftArrow, setShowRightArrow]);

  useEffect(() => {
    if (!loading && divRef.current && listings.length > 0) {
      handleScroll();
    }
  }, [loading, listings]);

  const scroll = (distance: number) => {
    if (divRef.current) {
      const { scrollLeft, scrollWidth, offsetWidth } = divRef.current;
      const maxScrollLeft = scrollWidth - offsetWidth;
      let newScrollLeft = scrollLeft + distance;

      if (newScrollLeft < 0) {
        newScrollLeft = 0;
      } else if (newScrollLeft > maxScrollLeft) {
        newScrollLeft = maxScrollLeft;
      }

      divRef.current.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
    }
  };

  const displayCssProp = {
    base: listings.length >= 2 ? 'block' : 'none',
    md: listings.length >= 2 ? 'block' : 'none',
    lg: listings.length >= columns ? 'block' : 'none',
  };

  return (
    <>
      {showLeftArrow && (
        <ChevronLeftIcon
          position="absolute"
          left={2}
          top="50%"
          w={8}
          h={8}
          zIndex="1"
          backgroundColor="gray.700"
          color="white"
          borderRadius={4}
          cursor="pointer"
          onClick={() => scroll(-SCROLL_DISTANCE)}
        />
      )}
      <Flex
        backgroundColor={isFeatured ? `${theme.colors.mainText}11` : theme.colors.mainBG}
        py={2}
        px={4}
        borderRadius={{ base: 'md', md: 'xl' }}
        direction="column"
      >
        {isFeatured ? (
          <Text fontWeight="semibold" fontSize="lg" mb={4}>
            Featured
          </Text>
        ) : (
          <Text fontSize="md" fontWeight="medium" mb={2} mt={12}>
            {titleText}
          </Text>
        )}
        <Flex
          overflowX="auto"
          gap={{ base: '16px', md: '24px' }}
          ref={divRef}
          onScroll={handleScroll}
          scrollSnapType="x mandatory"
        >
          <ListingCards
            listings={listings}
            category={category}
            loading={loading}
            actionButtons={actionButtons}
            columns={columns}
          />
        </Flex>
      </Flex>
      {showRightArrow && (
        <ChevronRightIcon
          position="absolute"
          right={2}
          top="50%"
          w={8}
          h={8}
          zIndex="1"
          backgroundColor="gray.700"
          color="white"
          borderRadius={4}
          display={displayCssProp}
          cursor="pointer"
          onClick={() => scroll(SCROLL_DISTANCE)}
        />
      )}
    </>
  );
};

export default ListingCarousel;
