import {
  Box,
  Popover,
  NumberInput,
  NumberInputField,
  HStack,
  FormControl,
  FormLabel,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FilterPopoverButton, FilterPopoverContent } from 'src/components/atoms';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';

type PriceRangeFilterProps = {
  isMobileView?: boolean;
  handleUserChangedPriceRange: React.Dispatch<React.SetStateAction<boolean>>;
};

const PriceRangeFilter = ({
  isMobileView,
  handleUserChangedPriceRange,
}: PriceRangeFilterProps): JSX.Element => {
  const { priceRange, setPriceRange, maxPrice, setStagedPriceRange } = useAttributeSelector();
  const defaultValue: [number, number] = [0, 0];

  const [inputValue, setInputValue] = useState(priceRange ?? defaultValue);
  const [tempPrice, setTempPrice] = useState(priceRange ?? defaultValue);

  const setMinInputValue = (e: string) => {
    const input = e;
    if (input) {
      const value = parseInt(input);
      setInputValue([value, inputValue[1]]);
      setStagedPriceRange([value, inputValue[1]]);
    } else {
      setInputValue([0, inputValue[1]]);
      setStagedPriceRange([0, inputValue[1]]);
    }
  };

  const setMaxInputValue = (e: string) => {
    const input = e;
    if (input) {
      const value = parseInt(input);
      setInputValue([inputValue[0], value]);
      setStagedPriceRange([inputValue[0], value]);
    } else {
      setInputValue([inputValue[0], 0]);
      setStagedPriceRange([inputValue[0], 0]);
    }
  };

  const format = (val: number | string) => {
    if (val === '') {
      return '$0';
    }
    return `$${val}`;
  };
  const parse = (val: string) => val.replace(/^\$/, '');

  useEffect(() => {
    // we need to wait for PriceRange to load or
    // inputValue will stay [0,0] as it is initialized
    setInputValue(priceRange ?? defaultValue);
    setTempPrice(priceRange ?? defaultValue);
  }, [priceRange]);

  useEffect(() => {
    setTempPrice(inputValue ?? defaultValue);
  }, [inputValue]);

  const handleMaxInputValue = (value: string) => {
    const parsedValue = parse(value);
    setMaxInputValue(parsedValue);
    if (parsedValue !== maxPrice.toString() && parsedValue !== '0') {
      handleUserChangedPriceRange(true);
    }
  };

  const handleMinInputValue = (value: string) => {
    const parsedValue = parse(value);
    setMinInputValue(parsedValue);
    if (parsedValue !== '0') {
      handleUserChangedPriceRange(true);
    }
  };

  return isMobileView ? (
    <Box px="2" pt="2">
      <HStack>
        <FormControl>
          <FormLabel>Min</FormLabel>
          <NumberInput
            onChange={handleMinInputValue}
            placeholder={'0'}
            value={format(tempPrice[0])}
            keepWithinRange={false}
            min={0}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>

        <FormControl>
          <FormLabel>Max</FormLabel>
          <NumberInput
            onChange={handleMaxInputValue}
            value={format(tempPrice[1])}
            keepWithinRange={false}
            max={maxPrice}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
      </HStack>
    </Box>
  ) : (
    <Popover placement="bottom-start">
      <FilterPopoverButton
        label="Price"
        hasFilter={!(defaultValue[0] === priceRange[0] && defaultValue[1] === priceRange[1])}
      />
      <FilterPopoverContent
        isCancelDisabled={!(defaultValue !== tempPrice)}
        isApplyDisabled={tempPrice[1] < tempPrice[0]}
        onClickApply={() => setPriceRange([tempPrice[0], tempPrice[1]])}
        onClickCancel={() => {
          setInputValue(defaultValue);
          setPriceRange(defaultValue);
        }}
      >
        <Box px="2" pt="2">
          <Stack direction={'column'} gap={4}>
            <FormControl>
              <FormLabel>Min</FormLabel>
              <NumberInput
                onChange={handleMinInputValue}
                value={format(tempPrice[0])}
                precision={0}
                placeholder="0"
                keepWithinRange={false}
                min={0}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Max</FormLabel>
              <NumberInput
                onChange={handleMaxInputValue}
                precision={0}
                value={format(tempPrice[1])}
                keepWithinRange={false}
                max={maxPrice}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
          </Stack>
        </Box>
      </FilterPopoverContent>
    </Popover>
  );
};

export default PriceRangeFilter;
