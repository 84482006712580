import { Button, ChakraProps, InputGroup, Input, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdFileCopy } from 'react-icons/md';
import { GlobalRoles } from 'src/api/v1-api';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';
import useRequest from 'src/utils/hooks/useRequest';

/**
 * Renders a button for creating a marketplace if the logged in user has the
 * proper permission.
 */
const CreateMarketplaceButton = (props: ChakraProps) => {
  const { api } = useRequest();
  const { marketplace } = useMarketplaceContext();
  const { hasRole } = useAuth();
  const toast = useToast();
  const [marketplaceName, setMarketplaceName] = useState('');
  const [loading, setLoading] = useState(false);
  const hasRequiredRole = hasRole(marketplace?.id?.toString() ?? '', GlobalRoles.Configuration);

  const handleCreate = async () => {
    if (loading || !marketplaceName) return;
    try {
      setLoading(true);
      const response = await api.marketplace.create.create({
        name: marketplaceName,
      });
      toast({
        status: 'success',
        duration: 5000,
        isClosable: true,
        title: `New marketplace '${response.name}' created (${response.slug})`,
      });
    } catch (error) {
      toast({
        status: 'error',
        duration: 5000,
        isClosable: true,
        title: `There was an error while creating marketplace`,
      });
    } finally {
      setLoading(false);
    }
  };

  if (!hasRequiredRole) {
    return null;
  }

  return (
    <InputGroup size="sm" maxW={400} alignSelf={{ base: 'flex-start', md: 'center' }} gap={2}>
      <Input
        maxW={200}
        name="marketplaceName"
        placeholder="marketplace name"
        focusBorderColor="primary.500"
        value={marketplaceName}
        onChange={(e) => setMarketplaceName(e.target.value)}
        margin={0}
      />
      <Button
        minWidth={'180px'}
        margin={0}
        colorScheme="primary"
        alignSelf="flex-end"
        leftIcon={<MdFileCopy />}
        onClick={handleCreate}
        isLoading={loading}
        isDisabled={loading}
        {...props}
      >
        Create Marketplace
      </Button>
    </InputGroup>
  );
};

export default CreateMarketplaceButton;
