import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Stack,
  Button,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Text,
  Link,
  IconButton,
  Input,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { MdInfoOutline } from 'react-icons/md';
import { useState, useContext } from 'react';
import { BasicCategory, ThirdPartyQuery } from 'src/api/v1-api';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { CategorySelector } from 'src/components/molecules';

interface SearchParam {
  key: string;
  value: string | string[];
}

interface ThirdPartyQueryFormProps {
  initialData?: ThirdPartyQuery;
  onSubmit: (data: ThirdPartyQuery) => void;
}

const ThirdPartyQueryForm: React.FC<ThirdPartyQueryFormProps> = ({ initialData, onSubmit }) => {
  const { marketplace } = useContext(MarketplaceContext);

  const [status, setStatus] = useState(initialData?.status || 'active');
  const [categoryId, setCategoryId] = useState<string>(initialData?.category?.toString() || '');
  const [platform, setPlatform] = useState(initialData?.third_party_platform || 'ebay');
  const [searchTerm, setSearchTerm] = useState(initialData?.search_term || '');
  const [frequency, setFrequency] = useState(initialData?.frequency || 7);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resultLimit, setResultLimit] = useState(initialData?.result_limit || 10);
  const [searchContext, setSearchContext] = useState(initialData?.search_context || '');
  const [expireAfterDays, setExpireAfterDays] = useState(initialData?.expire_after_days);

  const initialSearchParams = initialData?.search_params
    ? Object.entries(initialData.search_params)
        .filter(([key]) => key !== 'subdomains')
        .map(([key, value]) => ({
          key,
          value: value as string,
        }))
    : [{ key: '', value: '' }];
  const [searchParams, setSearchParams] = useState<SearchParam[]>(initialSearchParams);

  const initialSubdomains =
    typeof initialData?.search_params === 'object' &&
    Array.isArray(initialData.search_params.subdomains)
      ? (initialData.search_params.subdomains as string[])
      : [];
  const [subdomains, setSubdomains] = useState<string[]>(initialSubdomains);

  const handleParamChange = (index: number, keyOrValue: 'key' | 'value', value: string) => {
    const newSearchParams = [...searchParams];
    newSearchParams[index][keyOrValue] = value;
    setSearchParams(newSearchParams);
  };

  const addParam = () => {
    setSearchParams([...searchParams, { key: '', value: '' }]);
  };

  const removeParam = (index: number) => {
    const newSearchParams = [...searchParams];
    newSearchParams.splice(index, 1);
    setSearchParams(newSearchParams);
  };

  const handleSubdomainChange = (index: number, value: string) => {
    const newSubdomains = [...subdomains];
    newSubdomains[index] = value;
    setSubdomains(newSubdomains);
  };

  const addSubdomain = () => {
    setSubdomains([...subdomains, '']);
  };

  const removeSubdomain = (index: number) => {
    const newSubdomains = [...subdomains];
    newSubdomains.splice(index, 1);
    setSubdomains(newSubdomains);
  };

  const frequencyOptions = [
    { label: 'Daily', value: 1 },
    { label: 'Every Other Day', value: 2 },
    { label: 'Weekly', value: 7 },
    { label: 'Bi-Weekly', value: 14 },
  ];

  const handleCategorySelect = (category: BasicCategory | null) => {
    if (category) {
      setCategoryId(category.id.toString());
    } else {
      setCategoryId('');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    setIsSubmitting(true);
    e.preventDefault();

    const paramsObject: { [key: string]: string | string[] } = {};
    searchParams.forEach((param) => {
      if (param.key && param.value) {
        paramsObject[param.key] = param.value;
      }
    });

    if (platform === 'craigslist') {
      paramsObject['subdomains'] = subdomains;
    }

    const submissionData = {
      id: initialData?.id,
      status: status,
      category: categoryId,
      third_party_platform: platform,
      search_term: searchTerm,
      frequency: frequency,
      search_params: JSON.stringify(paramsObject),
      result_limit: resultLimit,
      search_context: searchContext,
      expire_after_days: expireAfterDays,
    };

    onSubmit(submissionData);
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack justify="flex-start" align="flex-start" spacing="28px">
        <Text lineHeight="1.5" fontWeight="semibold" fontSize="16px">
          Saved Searches
        </Text>

        <FormControl id="status">
          <FormLabel>Status</FormLabel>
          <Select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option key="active" value="active">
              Active
            </option>
            <option key="inactive" value="inactive">
              Inactive
            </option>
          </Select>
        </FormControl>

        <FormControl id="categoryId">
          <FormLabel>Category</FormLabel>
          <CategorySelector
            marketplaceId={marketplace?.id}
            selectedCategoryId={categoryId}
            onCategorySelect={handleCategorySelect}
            isRequired
            mode="save-search"
          />
        </FormControl>

        <FormControl id="platform">
          <FormLabel>Third Party Platform</FormLabel>
          <Select value={platform} onChange={(e) => setPlatform(e.target.value)} required>
            <option key="ebay" value="ebay">
              eBay
            </option>
            <option key="craigslist" value="craigslist">
              Craigslist
            </option>
            <option key="facebook" value="facebook">
              Facebook
            </option>
          </Select>
        </FormControl>

        <FormControl id="frequency">
          <FormLabel>Frequency</FormLabel>
          <Select value={frequency} onChange={(e) => setFrequency(Number(e.target.value))}>
            {frequencyOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl id="search-term">
          <FormLabel>Search Term</FormLabel>
          <Textarea
            width="100%"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            required
          />
        </FormControl>

        {platform === 'craigslist' && (
          <FormControl id="subdomains">
            <FormLabel>Locations</FormLabel>
            <Alert status="info" mb={4}>
              <AlertIcon />
              <AlertDescription fontSize="xs">
                Enter the subdomains of the Craigslist sites you want to search. For example, if you
                want to search the New York Craigslist site, enter &quot;newyork&quot;.
              </AlertDescription>
            </Alert>
            {subdomains.map((subdomain, index) => (
              <Stack key={index} direction="row" align="center" mb={2}>
                <Input
                  placeholder="Value"
                  value={subdomain}
                  onChange={(e) => handleSubdomainChange(index, e.target.value)}
                />
                <IconButton
                  aria-label="Remove location"
                  icon={<CloseIcon />}
                  onClick={() => removeSubdomain(index)}
                />
              </Stack>
            ))}
            <Button size="sm" onClick={addSubdomain}>
              Add Location
            </Button>
          </FormControl>
        )}

        <FormControl id="search-params">
          <FormLabel>Search Params</FormLabel>
          {searchParams.map((param, index) => (
            <Stack key={index} direction="row" align="center" mb={2}>
              <Input
                placeholder="Key"
                value={param.key}
                onChange={(e) => handleParamChange(index, 'key', e.target.value)}
              />
              <Input
                placeholder="Value"
                value={param.value}
                onChange={(e) => handleParamChange(index, 'value', e.target.value)}
              />
              <IconButton
                aria-label="Remove param"
                icon={<CloseIcon />}
                onClick={() => removeParam(index)}
              />
            </Stack>
          ))}
          <Button size="sm" onClick={addParam}>
            Add Param
          </Button>
        </FormControl>

        <FormControl id="result-limit">
          <FormLabel>
            Result Limit (Optional)&nbsp;
            <Tooltip
              label="Limit the number of listings created by this search. A maximum of 120 listings can be created."
              aria-label="A tooltip"
            >
              <span>
                <Icon as={MdInfoOutline} boxSize={4} />
              </span>
            </Tooltip>
          </FormLabel>
          <Input
            type="number"
            width={100}
            value={resultLimit}
            max={120}
            onChange={(e) => setResultLimit(Number(e.target.value))}
          />
        </FormControl>

        <FormControl id="expire_after_days">
          <FormLabel>
            Days Listings are Active (Optional)&nbsp;
            <Tooltip
              label="Listings will automatically expire after this many days"
              aria-label="A tooltip"
            >
              <span>
                <Icon as={MdInfoOutline} boxSize={4} />
              </span>
            </Tooltip>
          </FormLabel>
          <Input
            type="number"
            width={100}
            value={expireAfterDays}
            onChange={(e) => setExpireAfterDays(Number(e.target.value))}
          />
        </FormControl>

        <FormControl id="context">
          <FormLabel>
            Search Context (Optional)&nbsp;
            <Tooltip
              label="Enter any additional information that will help us understand your search."
              aria-label="A tooltip"
            >
              <span>
                <Icon as={MdInfoOutline} boxSize={4} />
              </span>
            </Tooltip>
          </FormLabel>
          <Textarea
            width="100%"
            value={searchContext}
            onChange={(e) => setSearchContext(e.target.value)}
          />
        </FormControl>
      </Stack>

      <Box display="flex" justifyContent="flex-end" pt={10}>
        <Link href="/admin/queries">
          <Button variant="outline" colorScheme="primary" mr={4}>
            Cancel
          </Button>
        </Link>
        <Button isLoading={isSubmitting} colorScheme="primary" type="submit">
          Save
        </Button>
      </Box>
    </form>
  );
};

export default ThirdPartyQueryForm;
