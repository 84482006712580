import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Portal,
  Slide,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { MdOutlineSearch } from 'react-icons/md';
import { useGlobalSearch } from 'src/contexts/globalSearch';
import { BLACK, WHITE } from 'src/constants/ui';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { useRouter } from 'next/router';

interface GlobalSearchProps {
  totalSocialCount: string | number;
}

const ROUTES_WITH_SEARCH = [
  '/_sites/[site]',
  '/_sites/[site]/category/[id]',
  '/_sites/[site]/account/listings',
  '/_sites/[site]/admin/approvals',
];

const GlobalSearch = ({ totalSocialCount }: GlobalSearchProps) => {
  const { searchQuery, setSearchQuery } = useGlobalSearch();
  const [searchValue, setSearchValue] = useState(searchQuery);
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { marketplace } = useMarketplaceContext();
  const router = useRouter();
  const theme = useTheme();

  useEffect(() => {
    setSearchValue(searchQuery);
  }, [searchQuery]);

  const textColor = theme.colors.navText ?? BLACK;

  const isSocialFansEnabled =
    marketplace?.functional?.show_total_social_media_audience &&
    marketplace?.social_profiles &&
    marketplace?.social_profiles.length > 0 &&
    totalSocialCount;

  const onSearch = () => {
    setSearchQuery(searchValue);
    if (searchValue && !ROUTES_WITH_SEARCH.includes(router.route)) {
      router.push('/');
    }
    onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <>
      <InputGroup
        size="sm"
        maxW={400}
        minW={300}
        alignSelf={{ base: 'flex-start', md: 'center' }}
        display={{ base: 'none', lg: 'flex' }}
      >
        <InputRightElement pointerEvents="none">
          <MdOutlineSearch color={`${textColor}66`} fontSize={'1.25rem'} />
        </InputRightElement>
        <Input
          type="search"
          placeholder="Search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={onSearch}
          _placeholder={{ color: `${textColor}CC` }}
        />
      </InputGroup>
      <Box pt={{ base: '10px', lg: '0' }} display={{ base: 'block', lg: 'none' }}>
        <Icon as={MdOutlineSearch} color={textColor} boxSize="6" onClick={onToggle} />
        <Portal>
          <Slide direction="top" in={isOpen} style={{ zIndex: 9, position: 'absolute' }}>
            <Box
              p="22px"
              mt={isSocialFansEnabled ? '78px' : '62px'}
              color={BLACK}
              backgroundColor={WHITE}
              roundedBottom={theme.border_radius.border_radius_2}
              shadow="md"
              display={{ base: 'block', lg: 'none' }}
            >
              <Flex direction="row" gap={2}>
                <Input
                  type="search"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <Button onClick={onSearch}>Search</Button>
              </Flex>
            </Box>
          </Slide>
        </Portal>
      </Box>
    </>
  );
};

export default GlobalSearch;
