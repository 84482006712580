import NextImage from 'next/image';
import { Image } from '@chakra-ui/react';
import { DEFAULT_IMAGE } from 'src/constants/config';
import { srcFromCDN } from 'src/utils/files';
import { SITE_MAX_WIDTH } from 'src/constants/ui';

interface Props {
  src: string;
  alt: string;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  priority?: boolean;
  columns?: number;
  width?: number;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

// Domains must be configured in next.config.js in images.remotePatterns
// in order to allow them for use with Next.js Image component
export const allowedImageDomains = [
  'dyn48y1sx7ytn.cloudfront.net',
  'dfib8oe3w8azk.cloudfront.net',
  'loma-files.s3.amazonaws.com',
  'loma-files.s3.us-west-1.amazonaws.com',
  'images.craigslist.org',
  'i.ebayimg.com',
];

function ImageWithFallback({
  src,
  alt,
  objectFit = 'cover',
  priority = false,
  columns = 1,
  width = SITE_MAX_WIDTH,
  onError,
}: Props) {
  let cdnSrc = src;
  if (!src || src === 'null' || src === '' || !src.includes('http')) {
    cdnSrc = DEFAULT_IMAGE;
  }
  cdnSrc = srcFromCDN(src, width, 'transform', columns);

  let url;
  try {
    url = new URL(cdnSrc);
  } catch (error) {
    console.error('error making URL', error);
  }
  const isAllowedDomain = !!url && allowedImageDomains.includes(url.hostname);

  const imageStyle: React.CSSProperties = { objectFit };

  if (isAllowedDomain) {
    return (
      <NextImage
        src={cdnSrc}
        alt={alt}
        draggable="false"
        style={imageStyle}
        priority={priority}
        onError={onError}
        fill
      />
    );
  } else {
    return <Image src={cdnSrc} alt={alt} onError={onError} draggable="false" objectFit="cover" />;
  }
}

export default ImageWithFallback;
