import {
  Alert,
  AlertDescription,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { PasswordField } from 'src/components/atoms';
import { useAuth } from 'src/utils/auth';
import { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import GoogleSigninButton from 'src/components/authentication/GoogleSigninButton';
import NextLink from 'next/link';
import { gaEvent } from 'src/utils/gtag';
import { MarketplaceContext } from 'src/contexts/marketplace';
import useSingleToast from 'src/utils/hooks/toast/useSingleToast';

interface LoginFormProps {
  userEmail?: string;
  onClose?: () => void;
  handleSuccessfulLogin?: () => void;
  setShowSignUp?: (show: boolean) => void;
}
const LoginForm: React.FC<LoginFormProps> = ({
  userEmail,
  onClose,
  handleSuccessfulLogin,
  setShowSignUp,
}) => {
  const { login } = useAuth();
  const router = useRouter();
  const showToast = useSingleToast();
  const theme = useTheme();

  const [email, setEmail] = useState(userEmail ?? '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { marketplace, marketplaceSlug } = useContext(MarketplaceContext);
  // prevent the Google button from flashing while marketplace is loading
  const hideGoogleAuth = Boolean(marketplace?.domain?.hide_google_auth);

  const handleForgotPassword = () => {
    onClose && onClose();
    router.push('/forgot-password');
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    if (loading) return;
    setLoading(true);
    setErrorMessage(null);
    try {
      if (!marketplaceSlug) return;
      const response = await login(email, password, marketplaceSlug);
      if (response.access === undefined) {
        if (response?.detail === 'The account is inactive') {
          setErrorMessage('Please verify your email address.');
          setLoading(false);
        } else {
          showToast({
            title: 'Invalid login info',
            description: 'Please check your email and password and try again.',
            status: 'info',
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        gaEvent({ action: 'login', category: 'engagement', label: 'method', value: `${email}` });

        onClose && onClose();
        handleSuccessfulLogin && handleSuccessfulLogin();
      }
    } catch (err) {
      showToast({
        title: 'Error',
        description: 'Something went wrong. Please try again later.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
        sentryAlert: {
          error: err,
          level: 'error',
          extras: {
            marketplaceId: marketplace?.id,
            email,
          },
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing="6" overflowY="auto" data-test="login-form">
      <Stack spacing="6">
        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
          <Heading size={{ base: 'xs', md: 'sm' }}>Log in to your account</Heading>
          <HStack spacing="1" justify="center">
            <Text color="muted">Don&apos;t have an account?</Text>
            {setShowSignUp ? (
              <Button
                variant="link"
                colorScheme="primary"
                onClick={() => setShowSignUp && setShowSignUp(true)}
              >
                Sign up
              </Button>
            ) : (
              <NextLink href="/signup" onClick={onClose}>
                <Button variant="link" colorScheme="primary">
                  Sign up
                </Button>
              </NextLink>
            )}
          </HStack>
        </Stack>
      </Stack>
      <Box py="4" px={{ base: '4', sm: '10' }} bg="white">
        {!hideGoogleAuth && <GoogleSigninButton isRegister={false} onClose={onClose} />}
        <form onSubmit={handleLogin}>
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  data-test="email-input-login-form"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <PasswordField value={password} onChange={(e) => setPassword(e.target.value)} />
            </Stack>
            <HStack justify="space-between">
              <Button variant="link" colorScheme="primary" size="sm" onClick={handleForgotPassword}>
                Forgot password?
              </Button>
            </HStack>
            <Stack spacing="6" pb={8}>
              <Button
                colorScheme="primary"
                size="md"
                type="submit"
                isLoading={loading}
                data-test="login-submit-btn-login-form"
              >
                Log in
              </Button>
            </Stack>
            {errorMessage && (
              <Alert status="error">
                <AlertDescription>{errorMessage}</AlertDescription>
              </Alert>
            )}
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};

export default LoginForm;
