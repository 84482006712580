import React, { ReactNode } from 'react';
import { Box, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider from 'react-slick';

interface CarouselProps {
  cards: ReactNode[];
}

const settings = {
  dots: true,
  arrows: false,
  fade: false,
  infinite: true,
  autoplay: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: false,
  lazyLoad: 'ondemand' as const,
  cssEase: 'linear',
  waitForAnimate: false,
};

export default function Carousel({ cards }: CarouselProps) {
  const [slider, setSlider] = React.useState<Slider | null>(null);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const top = useBreakpointValue({ base: '50%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '10px' });

  React.useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Box
      position={'relative'}
      width={'full'}
      overflow={'hidden'}
      visibility={isLoaded ? 'visible' : 'hidden'}
      h="full"
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        colorScheme="messenger"
        borderRadius="full"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}
      >
        <BiLeftArrowAlt />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        colorScheme="messenger"
        borderRadius="full"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}
      >
        <BiRightArrowAlt />
      </IconButton>
      {/* Slider */}
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {cards.map((card, index) => (
          <Box
            key={index}
            px={2}
            py={4}
            display="flex !important"
            justifyContent="center"
            alignItems="center"
            h="full"
          >
            {card}
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
