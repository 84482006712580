import { Box, useMediaQuery, Show } from '@chakra-ui/react';
import { useState, useRef, useCallback } from 'react';
import { Map as ReactMap, NavigationControl, Marker, Popup, MapRef } from 'react-map-gl';
import * as turf from '@turf/turf';
import { useResizeDetector } from 'react-resize-detector';
import 'mapbox-gl/dist/mapbox-gl.css';
import { BasicAttributeValue, Listing } from 'src/api/v1-api';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';
import { apiRequest } from 'src/utils/fetchUtils';
import { useAuth } from 'src/utils/auth';
import { useDeepEffect } from 'src/utils/hooks';
import Pin from './pin';
import useSingleToast from 'src/utils/hooks/toast/useSingleToast';
import { MAP_LISTINGS_PER_PAGE } from 'src/constants/marketplace';
import PinCard from './pin-card';
import { useGlobalSearch } from 'src/contexts/globalSearch';

interface Props {
  fetchUrl: string;
  initialMapFetchUrl?: string;
  requiresAuth?: boolean;
  mapResultsPerPage?: number;
}

interface MapCoordinates {
  sw_lng: number;
  sw_lat: number;
  ne_lng: number;
  ne_lat: number;
}

const ListingsMap = ({
  fetchUrl,
  initialMapFetchUrl,
  requiresAuth,
  mapResultsPerPage = MAP_LISTINGS_PER_PAGE,
}: Props): JSX.Element => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const showToast = useSingleToast();
  const { selectedAttributes: filters, priceRange } = useAttributeSelector();
  const { searchQuery } = useGlobalSearch();
  const { getToken } = useAuth();
  const mapRef = useRef<MapRef | null>(null);
  const { width, height, ref } = useResizeDetector();

  if (typeof mapResultsPerPage === 'string') {
    mapResultsPerPage = parseInt(mapResultsPerPage);
  }

  const [listings, setListings] = useState<Listing[]>([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [mapBoundaries, setMapBoundaries] = useState<MapCoordinates>({
    sw_lng: 0,
    sw_lat: 0,
    ne_lng: 0,
    ne_lat: 0,
  });
  const [currentMapState, setCurrentMapState] = useState<{
    longitude: number;
    latitude: number;
    zoom: number;
  }>(() => {
    const defaultState = {
      longitude: -98.35,
      latitude: 39.5,
      zoom: 4,
    };

    try {
      if (typeof window === 'undefined') {
        return defaultState;
      }

      const storedState = localStorage.getItem('storedMapState');
      if (!storedState) {
        return defaultState;
      }

      return JSON.parse(storedState);
    } catch (error) {
      console.warn('Unable to access localStorage:', error);
      return defaultState;
    }
  });

  const pins = (listings ?? []).filter(
    (item) =>
      typeof item.lat === 'string' &&
      (item.lat as string).trim() !== '' &&
      typeof item.lon === 'string' &&
      (item.lon as string).trim() !== '',
  );
  const [repeatedPins, setRepeatedPins] = useState<Listing[]>([]);

  const addressValue = selectedListing
    ? [
        ...(selectedListing.listing_attribute_values ?? []),
        ...(selectedListing.variants ?? []).reduce<BasicAttributeValue[]>((acc, cur) => {
          return Array.isArray(cur.variant_attribute_values) &&
            cur.variant_attribute_values.length > 0
            ? [...acc, ...cur.variant_attribute_values]
            : [...acc];
        }, []),
      ].find((attribute_value) => attribute_value.attribute?.format === 'location')
    : null;
  const address = addressValue
    ? `${addressValue.value_object?.city}, ${addressValue.value_object?.state}`
    : '';
  const regularPrice = selectedListing?.lowest_regular_price;
  const offeredPrice = selectedListing?.lowest_offered_price;

  let imageHeight = 12;
  if ((regularPrice || offeredPrice) && address) {
    imageHeight = 20;
  }

  useDeepEffect(() => {
    let subscribed = true;

    (async () => {
      try {
        if (!fetchUrl.includes('undefined')) {
          const params = new URLSearchParams();

          // If there's a price range, add it to the params
          if (priceRange[0] !== 0 || priceRange[1] !== 0) {
            params.append('price', `${priceRange[0]}-${priceRange[1]}`);
          }

          if (searchQuery) {
            params.append('search', searchQuery);
          }

          for (const [key, values] of Object.entries(filters)) {
            for (const value of values) {
              if (value.toString().length > 0) {
                params.append(key, value.toString());
              }
            }
          }

          if (
            mapBoundaries.sw_lng !== 0 ||
            mapBoundaries.sw_lat !== 0 ||
            mapBoundaries.ne_lng !== 0 ||
            mapBoundaries.ne_lat !== 0
          ) {
            params.append('sw_lng', mapBoundaries.sw_lng.toString());
            params.append('sw_lat', mapBoundaries.sw_lat.toString());
            params.append('ne_lng', mapBoundaries.ne_lng.toString());
            params.append('ne_lat', mapBoundaries.ne_lat.toString());
          }

          params.append('page_size', mapResultsPerPage.toString());
          const paramsString = params.toString();
          fetchUrl = initialMapFetchUrl ?? fetchUrl;
          let fullUrl = fetchUrl;
          if (paramsString) {
            if (!fetchUrl.includes('?')) {
              fullUrl = fetchUrl + '?' + paramsString;
            } else if (!fetchUrl.endsWith('&')) {
              fullUrl = fetchUrl + '&' + paramsString;
            } else {
              fullUrl = fetchUrl + paramsString;
            }
          }

          // Remove "page" and "viewType" from the URL if they exist to prevent conflicts
          fullUrl = fullUrl.replace(/([&?])page=\d+/, '$1').replace(/([&?])viewType=\w+/, '$1');

          // Clean up any trailing "?" or "&" if they exist after the replacements
          fullUrl = fullUrl.replace(/[?&]$/, '');

          let token = null;
          if (requiresAuth) {
            token = await getToken();
          }

          const response = await apiRequest('GET', fullUrl, token);
          if (subscribed) {
            setListings(response.results);
          }
        }
      } catch (error) {
        showToast({
          title: 'Error.',
          description: 'Unable to fetch listings, please try again later.',
          status: 'info',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
          sentryAlert: {
            error: error,
            level: 'error',
            extras: {
              fetchUrl,
              filters,
              priceRange,
              requiresAuth,
              mapBoundaries,
              searchQuery,
            },
          },
        });
      }
    })();

    return () => {
      subscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUrl, filters, priceRange, requiresAuth, mapBoundaries, searchQuery]);

  const onMapLoad = useCallback(() => {
    let storedMapState = null;

    // Only access localStorage in browser environment
    if (typeof window !== 'undefined') {
      const localMapState = localStorage.getItem('storedMapState');
      storedMapState = localMapState ? JSON.parse(localMapState) : null;
    }

    if (!mapRef.current) {
      return;
    }

    const map = mapRef.current.getMap();
    map.keyboard.disableRotation();
    map.touchZoomRotate.disableRotation();

    if (storedMapState) {
      mapRef.current.setCenter({
        lng: storedMapState.longitude,
        lat: storedMapState.latitude,
      });
      mapRef.current.setZoom(storedMapState.zoom);

      // Trigger initial boundary update after stored state is applied
      const mapCoordinates = map.getBounds().toArray();
      if (mapCoordinates) {
        setMapBoundaries({
          sw_lng: mapCoordinates[0][0],
          sw_lat: mapCoordinates[0][1],
          ne_lng: mapCoordinates[1][0],
          ne_lat: mapCoordinates[1][1],
        });
      }
    }

    map.on('zoomend', () => {
      const center = map.getCenter();
      const zoom = map.getZoom();
      if (center && zoom) {
        setCurrentMapState({
          longitude: center.lng,
          latitude: center.lat,
          zoom: zoom,
        });
        localStorage.setItem(
          'storedMapState',
          JSON.stringify({
            longitude: center.lng,
            latitude: center.lat,
            zoom: zoom,
          }),
        );
      }

      const mapCoordinates = map.getBounds().toArray();
      if (mapCoordinates) {
        setMapBoundaries({
          sw_lng: mapCoordinates[0][0],
          sw_lat: mapCoordinates[0][1],
          ne_lng: mapCoordinates[1][0],
          ne_lat: mapCoordinates[1][1],
        });
      }
    });

    map.on('moveend', () => {
      const center = map.getCenter();
      const zoom = map.getZoom();
      if (center && zoom) {
        setCurrentMapState({
          longitude: center.lng,
          latitude: center.lat,
          zoom: zoom,
        });
        localStorage.setItem(
          'storedMapState',
          JSON.stringify({
            longitude: center.lng,
            latitude: center.lat,
            zoom: zoom,
          }),
        );
      }

      const mapCoordinates = map.getBounds().toArray();
      if (mapCoordinates) {
        setMapBoundaries({
          sw_lng: mapCoordinates[0][0],
          sw_lat: mapCoordinates[0][1],
          ne_lng: mapCoordinates[1][0],
          ne_lat: mapCoordinates[1][1],
        });
      }
    });
  }, []);

  useDeepEffect(() => {
    if (mapLoaded) return;
    if (localStorage.getItem('storedMapState')) return;

    // set the map boundaries based on the pin locations
    if (pins.length > 0 && width && height) {
      const coordinates = pins.map((pin) => [Number(pin.lon), Number(pin.lat)]);

      // If there's only one pin, center the map on this pin
      if (coordinates.length === 1) {
        const [lon, lat] = coordinates[0];
        mapRef.current?.getMap()?.flyTo({ center: [lon, lat], zoom: 10, duration: 1000 });
        setMapLoaded(true);
        return;
      }

      const bbox = turf.bbox(turf.lineString(coordinates));
      const bounds: [number, number, number, number] = [bbox[0], bbox[1], bbox[2], bbox[3]];

      const padding = {
        top: Math.round(height / 12) + 10,
        bottom: Math.round(height / 12) + 10,
        left: Math.round(width / 12) + 10,
        right: Math.round(width / 12) + 10,
      };

      mapRef.current?.getMap()?.fitBounds(bounds, {
        padding: padding,
        duration: 1000,
      });
      setMapLoaded(true);
    }
  }, [pins, width, height]);

  const handleSelectedListing = (listing: Listing) => {
    setSelectedListing({ ...listing });
  };

  useDeepEffect(() => {
    const selectedKey = `${selectedListing?.lat},${selectedListing?.lon}`;

    const duplicates: Listing[] = listings.filter((listing) => {
      if (listing.lat !== undefined && listing.lon !== undefined) {
        const key = `${listing.lat},${listing.lon}`;
        return key === selectedKey;
      }
    });

    setRepeatedPins(duplicates.slice(0, 10));
  }, [pins, selectedListing]);

  return (
    <Box width="100%" height={isMobile ? 400 : 640} position="relative" ref={ref} pb={8}>
      {currentMapState && (
        <ReactMap
          mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
          initialViewState={currentMapState}
          style={{ width: '100%', height: '100%', borderRadius: isMobile ? '0.375rem' : '0.75rem' }}
          mapStyle="mapbox://styles/mapbox/streets-v9?optimize=true"
          ref={mapRef}
          onLoad={onMapLoad}
          // prevent accidental 3D controls
          touchPitch={false}
          dragRotate={false}
          pitchWithRotate={false}
        >
          <NavigationControl />
          {pins.map((pin, index) => (
            <Marker
              key={`pin-${index}`}
              longitude={pin.lon as number}
              latitude={pin.lat as number}
              anchor="bottom"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setSelectedListing({ ...pin });
                handleSelectedListing({ ...pin });
              }}
            >
              <Pin />
            </Marker>
          ))}
          {repeatedPins.length !== 0 && selectedListing ? (
            <>
              <Show below="sm">
                <Popup
                  anchor="top"
                  longitude={Number(selectedListing.lon)}
                  latitude={Number(selectedListing.lat)}
                  closeButton={false}
                  focusAfterOpen={false}
                  onClose={() => setSelectedListing(null)}
                  style={{ maxWidth: isMobile ? '75%' : 300, maxHeight: 185, overflowY: 'scroll' }}
                >
                  {repeatedPins.map((listing) => (
                    <PinCard
                      key={listing.id}
                      listing={listing}
                      imageHeight={imageHeight}
                      address={address}
                    />
                  ))}
                </Popup>
              </Show>
              <Show above="sm">
                <Popup
                  anchor="top"
                  longitude={Number(selectedListing.lon)}
                  latitude={Number(selectedListing.lat)}
                  closeButton={false}
                  focusAfterOpen={false}
                  onClose={() => setSelectedListing(null)}
                  style={{ maxWidth: isMobile ? '75%' : 300, maxHeight: 370, overflowY: 'scroll' }}
                >
                  {repeatedPins.map((listing) => (
                    <PinCard
                      key={listing.id}
                      listing={listing}
                      imageHeight={imageHeight}
                      address={address}
                    />
                  ))}
                </Popup>
              </Show>
            </>
          ) : (
            selectedListing && (
              <Popup
                anchor="top"
                longitude={Number(selectedListing.lon)}
                latitude={Number(selectedListing.lat)}
                closeButton={false}
                focusAfterOpen={false}
                onClose={() => setSelectedListing(null)}
                style={{ maxWidth: isMobile ? '75%' : 300 }}
              >
                <PinCard listing={selectedListing} imageHeight={imageHeight} address={address} />
              </Popup>
            )
          )}
        </ReactMap>
      )}
    </Box>
  );
};

export default ListingsMap;
