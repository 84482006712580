import {
  Box,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Popover,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FilterPopoverButton, FilterPopoverContent } from 'src/components/atoms';
// TODO(Sean): Handle currency formatting based on attribute
// import { formatPrice } from 'src/utils/common';
import { ListingFilterItemProps } from '.';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';
import { isEqual } from 'lodash';

const NumericFilter = ({
  attributeId,
  isMobileView,
  decimalPlaces,
}: ListingFilterItemProps): JSX.Element => {
  const {
    attributes,
    selectedAttributes,
    setSelectedAttributes,
    stagedAttributes,
    setStagedAttributes,
  } = useAttributeSelector();
  const attribute = attributes.find((a) => a.name === attributeId);
  const [tempValue, setTempValue] = useState([0, 0]);
  const [defaultValue, setDefaultValue] = useState([0, 0]);

  useEffect(() => {
    if (
      attribute?.options &&
      'min_value' in attribute.options &&
      'max_value' in attribute.options
    ) {
      const min = Number(attribute.options.min_value ?? 0);
      const max = Number(attribute.options.max_value ?? 0);
      const _defaultValue = [min, max];
      setDefaultValue(_defaultValue);
      setTempValue(_defaultValue);
    }
    if (attributeId in selectedAttributes) {
      setTempValue([
        Number(selectedAttributes[attributeId][0]),
        Number(selectedAttributes[attributeId][1]),
      ]);
    }
  }, [attribute, selectedAttributes]);

  const handleMobileChange = (value: [number, number]) => {
    setTempValue(value);
    setStagedAttributes({
      ...stagedAttributes,
      [attributeId]: [value[0].toString(), value[1].toString()],
    });
  };

  const handleChange = (min: number, max: number) => {
    setTempValue([isNaN(min) ? 0 : min, isNaN(max) ? 0 : max]);
  };

  return isMobileView ? (
    <Box px="2" pt="2">
      <Stack direction={'column'}>
        <FormControl>
          <FormLabel>Min</FormLabel>
          <NumberInput
            onChange={(_, value) => handleMobileChange([value, tempValue[1]])}
            value={tempValue[0]}
            precision={decimalPlaces}
            placeholder={'0'}
            keepWithinRange={false}
            min={0}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>

        <FormControl>
          <FormLabel>Max</FormLabel>
          <NumberInput
            onChange={(_, value) => handleChange(value, tempValue[1])}
            value={tempValue[1]}
            precision={decimalPlaces}
            keepWithinRange={false}
            min={tempValue[0]}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
      </Stack>
    </Box>
  ) : (
    <Popover placement="bottom-start">
      <FilterPopoverButton label={attribute?.name} />
      <FilterPopoverContent
        isCancelDisabled={isEqual(defaultValue, tempValue)}
        onClickApply={() => {
          setSelectedAttributes({
            ...selectedAttributes,
            [attributeId]: [tempValue[0].toString(), tempValue[1].toString()],
          });
        }}
        onClickCancel={() => {
          setTempValue(defaultValue);
          setSelectedAttributes({
            ...selectedAttributes,
            [attributeId]: [defaultValue[0].toString(), defaultValue[1].toString()],
          });
        }}
      >
        <Box px="2" pt="2">
          <Stack direction={'column'} gap={4}>
            <FormControl>
              <FormLabel>Min</FormLabel>
              <NumberInput
                onChange={(_, value) => handleChange(value, tempValue[1])}
                value={tempValue[0]}
                precision={decimalPlaces}
                placeholder="0"
                keepWithinRange={false}
                min={0}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Max</FormLabel>
              <NumberInput
                onChange={(_, value) => handleChange(tempValue[0], value)}
                value={tempValue[1]}
                precision={decimalPlaces}
                keepWithinRange={false}
                min={tempValue[0]}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
          </Stack>
        </Box>
      </FilterPopoverContent>
    </Popover>
  );
};

export default NumericFilter;
