import {
  Box,
  Container,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
  Icon,
  useTheme,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { FaStoreAlt } from 'react-icons/fa';
import { BLACK, CONTAINER_MAX_WIDTH_1, WHITE } from 'src/constants/ui';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { getSocialProfileIcon } from 'src/utils/socialProfileIcons';
import { useRouter } from 'next/router';
import { CollectEmailsModule } from 'src/components/atoms';
import { srcFromCDN } from 'src/utils/files';

const Footer = (): JSX.Element => {
  const router = useRouter();
  const { marketplace, logoIsCircle, supportEmail } = useContext(MarketplaceContext);
  const theme = useTheme();
  const bgColor = theme.colors.navBG ?? WHITE;
  const textColor = theme.colors.navText ?? BLACK;

  const currentPage = router.pathname;
  let footerPage = '';
  if (currentPage === '/_sites/[site]') {
    footerPage = 'home';
  } else if (
    currentPage.includes('/listing') &&
    !currentPage.includes('/edit') &&
    !currentPage.includes('/create')
  ) {
    footerPage = 'listing';
  } else if (currentPage.includes('/category')) {
    footerPage = 'category';
  }

  return (
    <Box bgColor={bgColor} color={textColor} style={{ marginTop: 'auto' }}>
      <Container
        maxW={CONTAINER_MAX_WIDTH_1}
        as="footer"
        role="contentinfo"
        py={{ base: '4', md: '6' }}
        px={4}
      >
        <CollectEmailsModule page={footerPage} />
        <Stack spacing={{ base: '2', md: '3' }}>
          <Stack
            justify="space-between"
            direction={{ base: 'column', md: 'row' }}
            align="center"
            borderBottom="2px"
            borderColor="gray.200"
            pb={4}
            spacing="4"
          >
            <Link href="/" _hover={{ textDecoration: 'none' }} hideBelow="md">
              <Flex justifyContent="space-between" alignItems="center" columnGap="8px">
                <Image
                  src={
                    marketplace?.logo_image ? srcFromCDN(marketplace?.logo_image, 32) : undefined
                  }
                  alt="Logo"
                  width="32px"
                  height="32px"
                  boxSize="32px"
                  objectFit="cover"
                  fallback={<Icon as={FaStoreAlt} color={textColor} boxSize="6" />}
                  borderRadius={logoIsCircle ? 'full' : ''}
                />
                <Heading fontSize={{ sm: 'md', md: 'lg', lg: 'xl' }} fontWeight="normal">
                  {marketplace?.name}
                </Heading>
              </Flex>
            </Link>
            <HStack
              hideFrom="md"
              maxWidth="100%"
              flexWrap="wrap"
              justifyContent="center"
              spacing={2}
            >
              {(marketplace?.social_profiles ?? [])
                .filter((sp) => sp.show_on_footer_menu)
                .map((social_profile) => (
                  <a href={social_profile?.url} target="_blank" key={social_profile?.id}>
                    <IconButton
                      aria-label={social_profile?.network}
                      backgroundColor="transparent"
                      color={textColor}
                      icon={getSocialProfileIcon(social_profile?.network)}
                    />
                  </a>
                ))}
            </HStack>
            <HStack
              width="full"
              flex={{ base: 1, md: 2 / 3 }}
              justifyContent="space-between"
              fontSize={{ base: 'sm', md: 'md' }}
            >
              {/* TODO(Sean): Create About page and link to it. */}
              {/* <Link href="/about" _hover={{ textDecoration: 'none' }} textAlign="center">
                About
              </Link> */}
              <Link href="/terms" _hover={{ textDecoration: 'none' }} textAlign="center">
                Terms
              </Link>
              <Link href="/privacy" _hover={{ textDecoration: 'none' }} textAlign="center">
                Privacy
              </Link>
              <Link
                href={`mailto:${supportEmail}`}
                _hover={{ textDecoration: 'none' }}
                textAlign="center"
                isExternal
              >
                Contact
              </Link>
            </HStack>
          </Stack>
          <Stack flex="1" alignItems="center">
            <HStack hideBelow="md" maxWidth="100%" flexWrap="wrap" justifyContent="center">
              {(marketplace?.social_profiles ?? [])
                .filter((sp) => sp.show_on_footer_menu)
                .map((social_profile) => (
                  <a href={social_profile?.url} target="_blank" key={social_profile?.id}>
                    <IconButton
                      aria-label={social_profile?.network}
                      backgroundColor="transparent"
                      _hover={{ color: 'transparent' }}
                      color={textColor}
                      icon={getSocialProfileIcon(
                        social_profile?.network,
                        '1.25rem',
                        theme.colors.navText,
                      )}
                    />
                  </a>
                ))}
            </HStack>
            <Text fontSize="sm" color={textColor}>
              &copy; {new Date().getFullYear()} {marketplace?.name}
            </Text>
            <Text fontSize="xs">
              <a href="https://www.loma.tech/" target="_blank">
                Powered by Loma
              </a>
            </Text>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
