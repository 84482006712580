import { HStack, Icon, PopoverTrigger, Text, useTheme } from '@chakra-ui/react';
import { ElementType } from 'react';
import { IoChevronDownSharp } from 'react-icons/io5';

interface FilterPopoverButtonProps {
  label: string | undefined;
  icon?: ElementType;
  selected?: boolean;
  width?: string;
  hasFilter?: boolean;
}

const FilterPopoverButton = (props: FilterPopoverButtonProps): JSX.Element => {
  const { label, icon, width = 'auto' } = props;
  const theme = useTheme();

  return (
    <PopoverTrigger>
      <HStack
        justify="space-between"
        position="relative"
        as="button"
        fontSize="sm"
        borderWidth="1px"
        paddingStart="3"
        paddingEnd="2"
        paddingY="2"
        spacing="1"
        width={width}
        minWidth="140px"
        borderRadius={theme.border_radius.border_radius_1}
      >
        {icon && <Icon as={icon} boxSize="2" />}
        <Text
          fontSize="md"
          height="24px"
          textAlign="left"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {label}
        </Text>
        <IoChevronDownSharp />
      </HStack>
    </PopoverTrigger>
  );
};

export default FilterPopoverButton;
