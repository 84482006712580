import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  VStack,
  useTheme,
  useToast,
} from '@chakra-ui/react';
import { useAuth } from 'src/utils/auth';
import { useEffect, useState } from 'react';
// import GoogleSigninButton from 'src/components/authentication/GoogleSigninButton';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import useRequest from 'src/utils/hooks/useRequest';

interface EmailListFormProps {
  onClose?: () => void;
  marketplaceId?: string;
}

interface postEmailList {
  email: string;
}

const EmailListForm: React.FC<EmailListFormProps> = ({ onClose, marketplaceId }) => {
  const { user } = useAuth();
  const { marketplace } = useMarketplaceContext();
  const toast = useToast();
  const { unauthPost: post } = useRequest();
  const theme = useTheme();

  const [email, setEmail] = useState(user?.email ?? '');
  const [canContact, setCanContact] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEmail(user?.email ?? '');
  }, [user]);

  const handleSignup = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      const signupUrl = marketplace?.id
        ? `/mailing_list/${marketplace?.id}/signup/`
        : `/mailing_list/${marketplaceId}/signup/`;
      await post<postEmailList>({
        url: signupUrl,
        body: { email },
      });
      toast({
        title: 'You have been added to the mailing list',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose && onClose();
      setLoading(false);
    } catch (err) {
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Stack spacing="6" overflowY="auto">
      <Box
        py="4"
        px={{ base: '8', sm: '10' }}
        bg="white"
        borderRadius={theme.border_radius.border_radius_2}
        mt={2}
      >
        <Stack spacing="6">
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center" pt={4} pb={4}>
            <Heading size="md" pb={4}>
              Join the {marketplace?.name} mailing list!
            </Heading>
            <Flex alignItems="start" justifyContent="start" direction="column" pb={2}>
              <Text whiteSpace="pre-line" fontSize={{ base: 'xs', md: 'sm' }} align="left">
                {marketplace?.functional.subscriber_signup_text || ''}
              </Text>
              <Text fontSize={{ base: 'xs', md: 'sm' }} align="left">
                Unsubscribe at any time.
              </Text>
            </Flex>
          </Stack>
        </Stack>
        <form onSubmit={handleSignup}>
          {/* <GoogleSigninButton isRegister={false} /> */}

          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </Stack>

            <Stack spacing="6">
              <Button
                colorScheme="primary"
                size="md"
                type="submit"
                isLoading={loading}
                isDisabled={!canContact}
              >
                Join Mailing List
              </Button>
            </Stack>

            <Stack spacing="6">
              <FormControl>
                <VStack>
                  <Checkbox
                    id="canContact"
                    isChecked={canContact}
                    onChange={(e) => setCanContact(e.target.checked)}
                    fontSize="xs"
                    colorScheme="primary"
                  >
                    <Text className="disclaimer-text" color="muted">
                      I agree that this information may be used to contact me
                    </Text>
                  </Checkbox>
                </VStack>
              </FormControl>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};

export default EmailListForm;
