import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import useRequest from 'src/utils/hooks/useRequest';
import { useCustomClipboard } from 'src/utils/marketplaceUtils';

interface ApexDomainEmailProps {
  apexDomain: string;
  emailStatus: string;
}

const CopyField = ({ text }: { text: string }) => {
  const { onCopy, hasCopied } = useCustomClipboard(text);
  return (
    <>
      <Text fontSize="xs" wordBreak="break-all">
        {text}
      </Text>
      <Button size="xs" onClick={onCopy}>
        {hasCopied ? 'Copied!' : 'Copy'}
      </Button>
    </>
  );
};

const ApexDomainEmail = (props: ApexDomainEmailProps) => {
  const { apexDomain, emailStatus } = props;
  const { api } = useRequest();
  const { marketplace, setMarketplace } = useContext(MarketplaceContext);
  const domain = marketplace?.domain;
  const [editEmailConfig, setEditEmailConfig] = useState(false);
  const [emailDomain, setEmailDomain] = useState(
    domain?.config?.email_domain || domain?.domain || '',
  );
  const [supportEmail, setSupportEmail] = useState(domain?.config?.support_email || 'support');
  const emailSlug = marketplace?.slug.replace(' ', '-').toLowerCase() ?? '';

  const generateApexEmail = async () => {
    if (!marketplace) return;
    try {
      const response = await api.marketplace.generate_apex_email_dns(marketplace.id, {
        email_domain: emailDomain,
        support_email: supportEmail,
      });
      setEditEmailConfig(false);
      setMarketplace({
        ...marketplace,
        domain: response,
      });
    } catch (error) {
      console.error('Error checking DNS:', error);
    }
  };

  const checkEmailDNS = async () => {
    if (!marketplace) return;
    try {
      const response = await api.marketplace.check_email_dns.get(marketplace.id);
      setMarketplace({
        ...marketplace,
        domain: response,
      });
    } catch (error) {
      console.error('Error checking DNS:', error);
    }
  };

  return (
    <>
      <Text>
        By adding additional DNS records you can enable Loma to send emails on behalf of your
        domain. This will allow transaction emails to come from:
      </Text>
      <Flex
        gap="5"
        p={{ base: 4, md: 4 }}
        w="100%"
        direction={{ base: 'column-reverse', md: 'row' }}
      >
        <Text fontSize="md">
          <b>{supportEmail}</b>@<b>{domain?.config?.email_domain || apexDomain}</b>
        </Text>
        <Text fontSize="md">vs.</Text>
        <Text fontSize="md">support+{emailSlug}@loma.tech</Text>
      </Flex>
      <Text>
        Once this change is made, customers responding to their transaction emails will go directly
        to the support email address on your domain. You must configure your email server to handle
        this address. To continue having Loma manage all support issues, have the support email
        address forward to &quot;support+{emailSlug}@loma.tech&quot;.
      </Text>
      <Text>
        You may also create a &quot;support&quot; user in your email system or an email alias to
        route incoming emails to your normal email address. In both cases, you will be responsible
        for handling any support inquiries and Loma won’t know about any inquiries unless your
        forward the email to &quot;support+{emailSlug}@loma.tech&quot;.
      </Text>
      <Text lineHeight="1.5" fontWeight="semibold" fontSize="16px" pt={4}>
        Custom Support Email Address
      </Text>
      <Text>
        You may change the email account that sends support emails which will handle email replies
        from users. By default, the email account used is &quot;support&quot;.
      </Text>
      <Text pb={2}>
        Emails will come from the subdomain or domain mapped to your Loma Marketplace. In some
        cases, you may wish to host Loma on a subdomain (like &quot;market.mysite.com&quot;) but
        have emails come from your root domain (like &quot;support@mysite.com&quot;). You may edit
        both values below then click &quot;Generate Email DNS&quot; to save and refresh the DNS
        values if necessary.
      </Text>
      <InputGroup>
        <Input
          name="support_email"
          type="text"
          value={supportEmail}
          onChange={(e) => setSupportEmail(e.target.value)}
        />
        <InputLeftAddon>@</InputLeftAddon>
        <Input
          name="email_domain"
          type="text"
          value={emailDomain}
          onChange={(e) => setEmailDomain(e.target.value)}
        />
      </InputGroup>
      <Text pt={2} pb={4}>
        Emails from Loma to your marketplace users will appear to come from: <b>{supportEmail}</b>@
        <b>{emailDomain}</b>
      </Text>
      <Divider />

      <Text lineHeight="1.5" fontWeight="semibold" fontSize="16px" pt={4}>
        Email DNS
      </Text>
      {domain?.config && !editEmailConfig ? (
        <>
          <Button onClick={() => setEditEmailConfig(true)}>Edit Email Domain</Button>

          <Text pt="2" fontWeight="medium">
            Email DNS Status
          </Text>
          <Flex gap="6" alignItems="center">
            {emailStatus === 'pending' && (
              <Text fontWeight="bold" color="gray" pl="2">
                Pending
              </Text>
            )}
            {emailStatus === 'active' && (
              <Text fontWeight="bold" color="green" pl="2">
                Active
              </Text>
            )}
            {(!emailStatus || emailStatus === 'inactive') && (
              <Text fontWeight="bold" color="red" pl="2">
                Inactive
              </Text>
            )}
            {emailStatus !== 'active' && (
              <Button onClick={() => checkEmailDNS()} isDisabled={emailStatus === ''}>
                Check Email DNS to Activate
              </Button>
            )}
          </Flex>

          <Accordion allowToggle pt={2}>
            <AccordionItem borderBottom={'none'}>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <i>Email DNS Instructions</i>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text pt="2">
                  To activate transaction emails coming from your domain, the following DNS records
                  must be added to your domain host. After adding the record, you may need to wait a
                  few minutes before our DNS recognizes the changes.
                </Text>
                <Grid templateColumns="repeat(7, 1fr)" gap={6} pt="4">
                  <GridItem>
                    <Text fontWeight="semibold">Type</Text>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Text fontWeight="semibold">Hostname</Text>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Text fontWeight="semibold">Data</Text>
                  </GridItem>

                  <GridItem>
                    <Text fontSize="xs">
                      {domain?.config?.dns?.mail_server?.type?.toUpperCase()}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Flex gap="3">
                      <CopyField text={domain?.config?.dns?.mail_server?.host} />
                    </Flex>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Flex gap="3">
                      <CopyField text={domain?.config?.dns?.mail_server?.data} />
                    </Flex>
                  </GridItem>

                  <GridItem>
                    <Text fontSize="xs">
                      {domain?.config?.dns?.subdomain_spf?.type?.toUpperCase()}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Flex gap="3">
                      <CopyField text={domain?.config?.dns?.subdomain_spf?.host} />
                    </Flex>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Flex gap="3">
                      <CopyField text={domain?.config?.dns?.subdomain_spf?.data} />
                    </Flex>
                  </GridItem>

                  <GridItem>
                    <Text fontSize="xs">{domain?.config?.dns?.dkim?.type?.toUpperCase()}</Text>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Flex gap="3">
                      <CopyField text={domain?.config?.dns?.dkim?.host} />
                    </Flex>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Flex gap="3">
                      <CopyField text={domain?.config?.dns?.dkim?.data} />
                    </Flex>
                  </GridItem>

                  <GridItem>
                    <Text fontSize="xs">TXT</Text>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Flex gap="3">
                      <CopyField
                        text={`_dmarc.${domain?.config?.email_domain || domain?.domain}`}
                      />
                    </Flex>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Flex gap="3">
                      <CopyField text="v=DMARC1; p=none;" />
                    </Flex>
                  </GridItem>
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>
      ) : (
        <>
          <Text>
            You must generate DNS records for use and upon changing your support email. After
            generating, the DNS records to add will appear under &quot;Email DNS Instructions&quot;.
          </Text>

          <Button onClick={() => generateApexEmail()}>Generate Email DNS</Button>
        </>
      )}
    </>
  );
};

export default ApexDomainEmail;
